import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7d3a5a6a = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _588c0e3e = () => interopDefault(import('../pages/health_check.vue' /* webpackChunkName: "pages/health_check" */))
const _4c272b84 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _74254493 = () => interopDefault(import('../pages/dashboard/inspire.vue' /* webpackChunkName: "pages/dashboard/inspire" */))
const _6e706b54 = () => interopDefault(import('../pages/dashboard/uploadfile.vue' /* webpackChunkName: "pages/dashboard/uploadfile" */))
const _5970880b = () => interopDefault(import('../pages/dashboard/_province/index.vue' /* webpackChunkName: "pages/dashboard/_province/index" */))
const _03d1e0be = () => interopDefault(import('../pages/dashboard/_province/announcement/index.vue' /* webpackChunkName: "pages/dashboard/_province/announcement/index" */))
const _6df16b3c = () => interopDefault(import('../pages/dashboard/_province/blog/index.vue' /* webpackChunkName: "pages/dashboard/_province/blog/index" */))
const _4d05a12c = () => interopDefault(import('../pages/dashboard/_province/blog-to-html/index.vue' /* webpackChunkName: "pages/dashboard/_province/blog-to-html/index" */))
const _4ce4438f = () => interopDefault(import('../pages/dashboard/_province/boost-company/index.vue' /* webpackChunkName: "pages/dashboard/_province/boost-company/index" */))
const _3b637125 = () => interopDefault(import('../pages/dashboard/_province/boost-posts/index.vue' /* webpackChunkName: "pages/dashboard/_province/boost-posts/index" */))
const _67685c40 = () => interopDefault(import('../pages/dashboard/_province/boost-vr/index.vue' /* webpackChunkName: "pages/dashboard/_province/boost-vr/index" */))
const _40389ff9 = () => interopDefault(import('../pages/dashboard/_province/company/index.vue' /* webpackChunkName: "pages/dashboard/_province/company/index" */))
const _07d6186c = () => interopDefault(import('../pages/dashboard/_province/guideline/index.vue' /* webpackChunkName: "pages/dashboard/_province/guideline/index" */))
const _9aa391ec = () => interopDefault(import('../pages/dashboard/_province/loan/index.vue' /* webpackChunkName: "pages/dashboard/_province/loan/index" */))
const _dd596dec = () => interopDefault(import('../pages/dashboard/_province/manage-event-online/index.vue' /* webpackChunkName: "pages/dashboard/_province/manage-event-online/index" */))
const _357c581e = () => interopDefault(import('../pages/dashboard/_province/maps/index.vue' /* webpackChunkName: "pages/dashboard/_province/maps/index" */))
const _cf03ccd6 = () => interopDefault(import('../pages/dashboard/_province/members/index.vue' /* webpackChunkName: "pages/dashboard/_province/members/index" */))
const _0a17e00f = () => interopDefault(import('../pages/dashboard/_province/posts/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/index" */))
const _bd1b33b6 = () => interopDefault(import('../pages/dashboard/_province/profile/index.vue' /* webpackChunkName: "pages/dashboard/_province/profile/index" */))
const _99a521e4 = () => interopDefault(import('../pages/dashboard/_province/projects-coming-soon/index.vue' /* webpackChunkName: "pages/dashboard/_province/projects-coming-soon/index" */))
const _1a92ffbe = () => interopDefault(import('../pages/dashboard/_province/qrcodes/index.vue' /* webpackChunkName: "pages/dashboard/_province/qrcodes/index" */))
const _105c2acc = () => interopDefault(import('../pages/dashboard/_province/short-url/index.vue' /* webpackChunkName: "pages/dashboard/_province/short-url/index" */))
const _7c8b9c18 = () => interopDefault(import('../pages/dashboard/_province/top-ads/index.vue' /* webpackChunkName: "pages/dashboard/_province/top-ads/index" */))
const _eb00a6de = () => interopDefault(import('../pages/dashboard/_province/tracking/index.vue' /* webpackChunkName: "pages/dashboard/_province/tracking/index" */))
const _0f41a0f8 = () => interopDefault(import('../pages/dashboard/_province/video-review/index.vue' /* webpackChunkName: "pages/dashboard/_province/video-review/index" */))
const _2d1d34d2 = () => interopDefault(import('../pages/dashboard/_province/video-review-new/index.vue' /* webpackChunkName: "pages/dashboard/_province/video-review-new/index" */))
const _70b23583 = () => interopDefault(import('../pages/dashboard/_province/zones/index.vue' /* webpackChunkName: "pages/dashboard/_province/zones/index" */))
const _a9b97bae = () => interopDefault(import('../pages/dashboard/_province/baanprompt/agents/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/agents/index" */))
const _2f536096 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/property-owner/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/property-owner/index" */))
const _27bbc71a = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-in/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-in/index" */))
const _3dc0b30f = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/index" */))
const _77f04702 = () => interopDefault(import('../pages/dashboard/_province/blog/create.vue' /* webpackChunkName: "pages/dashboard/_province/blog/create" */))
const _8b2cd2bc = () => interopDefault(import('../pages/dashboard/_province/customers/agents/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/agents/index" */))
const _f187ccc6 = () => interopDefault(import('../pages/dashboard/_province/customers/companies/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies/index" */))
const _6ab88cb3 = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/index" */))
const _ee4976a4 = () => interopDefault(import('../pages/dashboard/_province/customers/normals/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/normals/index" */))
const _1aa13fd2 = () => interopDefault(import('../pages/dashboard/_province/guideline/create.vue' /* webpackChunkName: "pages/dashboard/_province/guideline/create" */))
const _3cf63284 = () => interopDefault(import('../pages/dashboard/_province/home-builder/leading/index.vue' /* webpackChunkName: "pages/dashboard/_province/home-builder/leading/index" */))
const _e96ec1c8 = () => interopDefault(import('../pages/dashboard/_province/line-broadcast/broadcast-campaign.vue' /* webpackChunkName: "pages/dashboard/_province/line-broadcast/broadcast-campaign" */))
const _711327ae = () => interopDefault(import('../pages/dashboard/_province/line-broadcast/dashboard-campaign.vue' /* webpackChunkName: "pages/dashboard/_province/line-broadcast/dashboard-campaign" */))
const _1883414c = () => interopDefault(import('../pages/dashboard/_province/manage-event-online/create-or-update.vue' /* webpackChunkName: "pages/dashboard/_province/manage-event-online/create-or-update" */))
const _ac6dac88 = () => interopDefault(import('../pages/dashboard/_province/manage-home-page/communication-bar/index.vue' /* webpackChunkName: "pages/dashboard/_province/manage-home-page/communication-bar/index" */))
const _0897e0e4 = () => interopDefault(import('../pages/dashboard/_province/manage-home-page/communication-pop-up/index.vue' /* webpackChunkName: "pages/dashboard/_province/manage-home-page/communication-pop-up/index" */))
const _44e54f0d = () => interopDefault(import('../pages/dashboard/_province/manage-home-page/pop-up/index.vue' /* webpackChunkName: "pages/dashboard/_province/manage-home-page/pop-up/index" */))
const _183b51f7 = () => interopDefault(import('../pages/dashboard/_province/manage-other-page/boost-company-ads/index.vue' /* webpackChunkName: "pages/dashboard/_province/manage-other-page/boost-company-ads/index" */))
const _c20d386e = () => interopDefault(import('../pages/dashboard/_province/members/create.vue' /* webpackChunkName: "pages/dashboard/_province/members/create" */))
const _dcadc99c = () => interopDefault(import('../pages/dashboard/_province/posts/apartment/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/apartment/index" */))
const _3e67d5be = () => interopDefault(import('../pages/dashboard/_province/posts/external/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/external/index" */))
const _56d58203 = () => interopDefault(import('../pages/dashboard/_province/posts/home-builder/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/home-builder/index" */))
const _95de09e0 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/index" */))
const _2dbf690e = () => interopDefault(import('../pages/dashboard/_province/posts/secondhand/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/secondhand/index" */))
const _dc86ac6c = () => interopDefault(import('../pages/dashboard/_province/profile/edit/index.vue' /* webpackChunkName: "pages/dashboard/_province/profile/edit/index" */))
const _4b968520 = () => interopDefault(import('../pages/dashboard/_province/projects-coming-soon/create.vue' /* webpackChunkName: "pages/dashboard/_province/projects-coming-soon/create" */))
const _2a1fb364 = () => interopDefault(import('../pages/dashboard/_province/short-url/create.vue' /* webpackChunkName: "pages/dashboard/_province/short-url/create" */))
const _05edd330 = () => interopDefault(import('../pages/dashboard/_province/super-admin/admins/index.vue' /* webpackChunkName: "pages/dashboard/_province/super-admin/admins/index" */))
const _6f615ab4 = () => interopDefault(import('../pages/dashboard/_province/super-admin/feature-management/index.vue' /* webpackChunkName: "pages/dashboard/_province/super-admin/feature-management/index" */))
const _7e81ea1c = () => interopDefault(import('../pages/dashboard/_province/super-admin/users/index.vue' /* webpackChunkName: "pages/dashboard/_province/super-admin/users/index" */))
const _c57f516c = () => interopDefault(import('../pages/dashboard/_province/top-ads/create.vue' /* webpackChunkName: "pages/dashboard/_province/top-ads/create" */))
const _596b005c = () => interopDefault(import('../pages/dashboard/_province/video-review-new/form/index.vue' /* webpackChunkName: "pages/dashboard/_province/video-review-new/form/index" */))
const _59828fda = () => interopDefault(import('../pages/dashboard/_province/video-review/newProject/index.vue' /* webpackChunkName: "pages/dashboard/_province/video-review/newProject/index" */))
const _32f8fd04 = () => interopDefault(import('../pages/dashboard/_province/video-review/secondHand/index.vue' /* webpackChunkName: "pages/dashboard/_province/video-review/secondHand/index" */))
const _656e74ca = () => interopDefault(import('../pages/dashboard/_province/zones/create.vue' /* webpackChunkName: "pages/dashboard/_province/zones/create" */))
const _69735933 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers/create/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers/create/index" */))
const _140d546b = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/create.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/create" */))
const _5f86d89a = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/leading-company/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/leading-company/index" */))
const _3f7bf40a = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/premium/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/premium/index" */))
const _69a70e9c = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/top-ads/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/top-ads/index" */))
const _a6ef8024 = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/vr/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/vr/index" */))
const _3c224042 = () => interopDefault(import('../pages/dashboard/_province/customers/companies/add-company/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies/add-company/index" */))
const _8b7cc860 = () => interopDefault(import('../pages/dashboard/_province/customers/normals/create.vue' /* webpackChunkName: "pages/dashboard/_province/customers/normals/create" */))
const _76cc3bc0 = () => interopDefault(import('../pages/dashboard/_province/home-builder/leading/create.vue' /* webpackChunkName: "pages/dashboard/_province/home-builder/leading/create" */))
const _7f78dd51 = () => interopDefault(import('../pages/dashboard/_province/manage-home-page/pop-up/create.vue' /* webpackChunkName: "pages/dashboard/_province/manage-home-page/pop-up/create" */))
const _35aa8ba1 = () => interopDefault(import('../pages/dashboard/_province/manage-other-page/enrolls/interest/index.vue' /* webpackChunkName: "pages/dashboard/_province/manage-other-page/enrolls/interest/index" */))
const _69a2d468 = () => interopDefault(import('../pages/dashboard/_province/posts/apartment/create.vue' /* webpackChunkName: "pages/dashboard/_province/posts/apartment/create" */))
const _c6b6905c = () => interopDefault(import('../pages/dashboard/_province/posts/approved/projects/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/approved/projects/index" */))
const _be3bfb1a = () => interopDefault(import('../pages/dashboard/_province/posts/approved/sale-and-rent/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/approved/sale-and-rent/index" */))
const _3ab7dfbb = () => interopDefault(import('../pages/dashboard/_province/posts/approved/secondhand/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/approved/secondhand/index" */))
const _606ad9bd = () => interopDefault(import('../pages/dashboard/_province/posts/external/create.vue' /* webpackChunkName: "pages/dashboard/_province/posts/external/create" */))
const _a8e1edca = () => interopDefault(import('../pages/dashboard/_province/posts/home-builder/create.vue' /* webpackChunkName: "pages/dashboard/_province/posts/home-builder/create" */))
const _7806a96a = () => interopDefault(import('../pages/dashboard/_province/posts/home-builder/premium/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/home-builder/premium/index" */))
const _14c2b1ae = () => interopDefault(import('../pages/dashboard/_province/posts/projects/create.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/create" */))
const _ea8bc436 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/exportfile/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/exportfile/index" */))
const _4e9e10a2 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/home-builder/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/home-builder/index" */))
const _4e6b2944 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/promo-inhouse/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/promo-inhouse/index" */))
const _2bc8e2aa = () => interopDefault(import('../pages/dashboard/_province/posts/rejected/projects/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/rejected/projects/index" */))
const _26e6e98c = () => interopDefault(import('../pages/dashboard/_province/posts/rejected/sale-and-rent/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/rejected/sale-and-rent/index" */))
const _05d46454 = () => interopDefault(import('../pages/dashboard/_province/posts/rejected/secondhand/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/rejected/secondhand/index" */))
const _629d6e65 = () => interopDefault(import('../pages/dashboard/_province/posts/secondhand/create.vue' /* webpackChunkName: "pages/dashboard/_province/posts/secondhand/create" */))
const _e99f011c = () => interopDefault(import('../pages/dashboard/_province/projects-coming-soon/interested/total.vue' /* webpackChunkName: "pages/dashboard/_province/projects-coming-soon/interested/total" */))
const _40fe44e4 = () => interopDefault(import('../pages/dashboard/_province/super-admin/admins/create.vue' /* webpackChunkName: "pages/dashboard/_province/super-admin/admins/create" */))
const _7ed69e0c = () => interopDefault(import('../pages/dashboard/_province/super-admin/users/create.vue' /* webpackChunkName: "pages/dashboard/_province/super-admin/users/create" */))
const _1a21e0bc = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/top-ads/create.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/top-ads/create" */))
const _0c340890 = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/vr/create.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/vr/create" */))
const _b1499986 = () => interopDefault(import('../pages/dashboard/_province/manage-other-page/enrolls/interest/create.vue' /* webpackChunkName: "pages/dashboard/_province/manage-other-page/enrolls/interest/create" */))
const _68374b20 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/promo-inhouse/create.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/promo-inhouse/create" */))
const _527693fc = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/premium/post-populars/_premiumId.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/premium/post-populars/_premiumId" */))
const _5201f24a = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/top-ads/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/top-ads/edit/_id" */))
const _9a2d076e = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/vr/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/vr/edit/_id" */))
const _184e2f08 = () => interopDefault(import('../pages/dashboard/_province/manage-other-page/enrolls/interest/details/_id.vue' /* webpackChunkName: "pages/dashboard/_province/manage-other-page/enrolls/interest/details/_id" */))
const _20f0afb6 = () => interopDefault(import('../pages/dashboard/_province/manage-other-page/enrolls/interest/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/manage-other-page/enrolls/interest/edit/_id" */))
const _1b265ad9 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/promo-inhouse/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/promo-inhouse/edit/_id" */))
const _6b720c2e = () => interopDefault(import('../pages/dashboard/_province/baanprompt/agents/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/agents/edit/_id" */))
const _0f736972 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/agents/register/_id.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/agents/register/_id" */))
const _165b8dd6 = () => interopDefault(import('../pages/dashboard/_province/customers/agents/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/customers/agents/edit/_id" */))
const _4a67acae = () => interopDefault(import('../pages/dashboard/_province/customers/agents/register/_id.vue' /* webpackChunkName: "pages/dashboard/_province/customers/agents/register/_id" */))
const _e7d6f986 = () => interopDefault(import('../pages/dashboard/_province/customers/companies/edit/_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies/edit/_id/index" */))
const _13df3f89 = () => interopDefault(import('../pages/dashboard/_province/customers/normals/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/customers/normals/edit/_id" */))
const _4f7cb90e = () => interopDefault(import('../pages/dashboard/_province/home-builder/leading/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/home-builder/leading/edit/_id" */))
const _c514aaf6 = () => interopDefault(import('../pages/dashboard/_province/posts/apartment/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/posts/apartment/edit/_id" */))
const _569e9394 = () => interopDefault(import('../pages/dashboard/_province/posts/external/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/posts/external/edit/_id" */))
const _30f2f3d8 = () => interopDefault(import('../pages/dashboard/_province/posts/home-builder/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/posts/home-builder/edit/_id" */))
const _5b1b5432 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/edit/_id" */))
const _1490c0de = () => interopDefault(import('../pages/dashboard/_province/posts/secondhand/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/posts/secondhand/edit/_id" */))
const _5ceefe3c = () => interopDefault(import('../pages/dashboard/_province/posts/secondhand/user/_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/secondhand/user/_id/index" */))
const _073ca2c5 = () => interopDefault(import('../pages/dashboard/_province/super-admin/users/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/super-admin/users/edit/_id" */))
const _332ad2ff = () => interopDefault(import('../pages/dashboard/_province/posts/projects/manage-vr/_id/edit/index.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/manage-vr/_id/edit/index" */))
const _86e14b78 = () => interopDefault(import('../pages/dashboard/_province/posts/projects/promo-inhouse/_id/coupons.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/promo-inhouse/_id/coupons" */))
const _a234270e = () => interopDefault(import('../pages/dashboard/_province/posts/projects/promo-inhouse/_id/participants.vue' /* webpackChunkName: "pages/dashboard/_province/posts/projects/promo-inhouse/_id/participants" */))
const _6eacf1b9 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers/_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers/_id/index" */))
const _088dabb2 = () => interopDefault(import('../pages/dashboard/_province/boost-posts/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/boost-posts/edit/_id" */))
const _ce2db292 = () => interopDefault(import('../pages/dashboard/_province/boost-vr/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/boost-vr/edit/_id" */))
const _69051e08 = () => interopDefault(import('../pages/dashboard/_province/customers/companies/_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies/_id/index" */))
const _1b361b78 = () => interopDefault(import('../pages/dashboard/_province/manage-home-page/pop-up/_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/manage-home-page/pop-up/_id/index" */))
const _02c66232 = () => interopDefault(import('../pages/dashboard/_province/members/permissions/_id.vue' /* webpackChunkName: "pages/dashboard/_province/members/permissions/_id" */))
const _03907929 = () => interopDefault(import('../pages/dashboard/_province/projects-coming-soon/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/projects-coming-soon/edit/_id" */))
const _7eaa5c15 = () => interopDefault(import('../pages/dashboard/_province/projects-coming-soon/interested/_index.vue' /* webpackChunkName: "pages/dashboard/_province/projects-coming-soon/interested/_index" */))
const _321d0483 = () => interopDefault(import('../pages/dashboard/_province/top-ads/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/top-ads/edit/_id" */))
const _0cf1d4d6 = () => interopDefault(import('../pages/dashboard/_province/zones/update/_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/zones/update/_id/index" */))
const _9547c4b2 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/agents/_id/verify.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/agents/_id/verify" */))
const _12e4219d = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-in/_id/accept-agent.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-in/_id/accept-agent" */))
const _4424ed54 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-in/_id/sent-agents.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-in/_id/sent-agents" */))
const _43f19024 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/assign-document/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/assign-document/index" */))
const _1661b096 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/interest/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/interest/index" */))
const _281df210 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/monthly/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/monthly/index" */))
const _7ec64ee7 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/offline-marketing/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/offline-marketing/index" */))
const _2495cd37 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/online-marketing/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/online-marketing/index" */))
const _5dd2da94 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/visit/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/visit/index" */))
const _2abd9673 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers/_id/edit.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers/_id/edit" */))
const _6764acce = () => interopDefault(import('../pages/dashboard/_province/customers/agents/_id/verify.vue' /* webpackChunkName: "pages/dashboard/_province/customers/agents/_id/verify" */))
const _1aebd16e = () => interopDefault(import('../pages/dashboard/_province/customers/companies-home-builder/_id/edit.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies-home-builder/_id/edit" */))
const _3602ec5a = () => interopDefault(import('../pages/dashboard/_province/customers/companies/_id/company_list.vue' /* webpackChunkName: "pages/dashboard/_province/customers/companies/_id/company_list" */))
const _58ff2556 = () => interopDefault(import('../pages/dashboard/_province/manage-event-online/promotion/_id/create.vue' /* webpackChunkName: "pages/dashboard/_province/manage-event-online/promotion/_id/create" */))
const _50bcc45e = () => interopDefault(import('../pages/dashboard/_province/super-admin/admins/_id/edit.vue' /* webpackChunkName: "pages/dashboard/_province/super-admin/admins/_id/edit" */))
const _434c0ace = () => interopDefault(import('../pages/dashboard/_province/video-review/newProject/_slot/create/index.vue' /* webpackChunkName: "pages/dashboard/_province/video-review/newProject/_slot/create/index" */))
const _11dfc1af = () => interopDefault(import('../pages/dashboard/_province/video-review/secondHand/_slot/create/index.vue' /* webpackChunkName: "pages/dashboard/_province/video-review/secondHand/_slot/create/index" */))
const _230ff420 = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/interest/details/_details_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/interest/details/_details_id/index" */))
const _d606b49a = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/monthly/details/_details_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/monthly/details/_details_id/index" */))
const _faa2241c = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/offline-marketing/details/_detail_id.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/offline-marketing/details/_detail_id" */))
const _78699cbc = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/online-marketing/details/_detail_id.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/online-marketing/details/_detail_id" */))
const _4716331e = () => interopDefault(import('../pages/dashboard/_province/baanprompt/refers-out/_id/visit/details/_details_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/baanprompt/refers-out/_id/visit/details/_details_id/index" */))
const _27fc46cf = () => interopDefault(import('../pages/dashboard/_province/video-review/newProject/_slot/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/video-review/newProject/_slot/edit/_id" */))
const _2705ee36 = () => interopDefault(import('../pages/dashboard/_province/video-review/secondHand/_slot/edit/_id.vue' /* webpackChunkName: "pages/dashboard/_province/video-review/secondHand/_slot/edit/_id" */))
const _3975d510 = () => interopDefault(import('../pages/dashboard/_province/manage-event-online/promotion/_id/_promotionId.vue' /* webpackChunkName: "pages/dashboard/_province/manage-event-online/promotion/_id/_promotionId" */))
const _6979ba3f = () => interopDefault(import('../pages/dashboard/_province/announcement/_company.vue' /* webpackChunkName: "pages/dashboard/_province/announcement/_company" */))
const _5a93d08d = () => interopDefault(import('../pages/dashboard/_province/banners/_type/index.vue' /* webpackChunkName: "pages/dashboard/_province/banners/_type/index" */))
const _ad41d338 = () => interopDefault(import('../pages/dashboard/_province/company/_id/index.vue' /* webpackChunkName: "pages/dashboard/_province/company/_id/index" */))
const _1c2dcb82 = () => interopDefault(import('../pages/dashboard/_province/short-url/_id.vue' /* webpackChunkName: "pages/dashboard/_province/short-url/_id" */))
const _1f9a8bd1 = () => interopDefault(import('../pages/dashboard/_province/banners/_type/create.vue' /* webpackChunkName: "pages/dashboard/_province/banners/_type/create" */))
const _11dc7a45 = () => interopDefault(import('../pages/dashboard/_province/blog/_id/edit.vue' /* webpackChunkName: "pages/dashboard/_province/blog/_id/edit" */))
const _679b01d6 = () => interopDefault(import('../pages/dashboard/_province/guideline/_id/edit.vue' /* webpackChunkName: "pages/dashboard/_province/guideline/_id/edit" */))
const _31cf3ca2 = () => interopDefault(import('../pages/dashboard/_province/manage-event-online/_eventId/detail.vue' /* webpackChunkName: "pages/dashboard/_province/manage-event-online/_eventId/detail" */))
const _58a80cbb = () => interopDefault(import('../pages/dashboard/_province/manage-event-online/_eventId/interest.vue' /* webpackChunkName: "pages/dashboard/_province/manage-event-online/_eventId/interest" */))
const _c9dcfd68 = () => interopDefault(import('../pages/dashboard/_province/members/_id/edit.vue' /* webpackChunkName: "pages/dashboard/_province/members/_id/edit" */))
const _7808c754 = () => interopDefault(import('../pages/dashboard/_province/banners/_type/_id/edit.vue' /* webpackChunkName: "pages/dashboard/_province/banners/_type/_id/edit" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _7d3a5a6a,
    name: "dashboard"
  }, {
    path: "/health_check",
    component: _588c0e3e,
    name: "health_check"
  }, {
    path: "/auth/login",
    component: _4c272b84,
    name: "auth-login"
  }, {
    path: "/dashboard/inspire",
    component: _74254493,
    name: "dashboard-inspire"
  }, {
    path: "/dashboard/uploadfile",
    component: _6e706b54,
    name: "dashboard-uploadfile"
  }, {
    path: "/dashboard/:province",
    component: _5970880b,
    name: "dashboard-province"
  }, {
    path: "/dashboard/:province/announcement",
    component: _03d1e0be,
    name: "dashboard-province-announcement"
  }, {
    path: "/dashboard/:province/blog",
    component: _6df16b3c,
    name: "dashboard-province-blog"
  }, {
    path: "/dashboard/:province/blog-to-html",
    component: _4d05a12c,
    name: "dashboard-province-blog-to-html"
  }, {
    path: "/dashboard/:province/boost-company",
    component: _4ce4438f,
    name: "dashboard-province-boost-company"
  }, {
    path: "/dashboard/:province/boost-posts",
    component: _3b637125,
    name: "dashboard-province-boost-posts"
  }, {
    path: "/dashboard/:province/boost-vr",
    component: _67685c40,
    name: "dashboard-province-boost-vr"
  }, {
    path: "/dashboard/:province/company",
    component: _40389ff9,
    name: "dashboard-province-company"
  }, {
    path: "/dashboard/:province/guideline",
    component: _07d6186c,
    name: "dashboard-province-guideline"
  }, {
    path: "/dashboard/:province/loan",
    component: _9aa391ec,
    name: "dashboard-province-loan"
  }, {
    path: "/dashboard/:province/manage-event-online",
    component: _dd596dec,
    name: "dashboard-province-manage-event-online"
  }, {
    path: "/dashboard/:province/maps",
    component: _357c581e,
    name: "dashboard-province-maps"
  }, {
    path: "/dashboard/:province/members",
    component: _cf03ccd6,
    name: "dashboard-province-members"
  }, {
    path: "/dashboard/:province/posts",
    component: _0a17e00f,
    name: "dashboard-province-posts"
  }, {
    path: "/dashboard/:province/profile",
    component: _bd1b33b6,
    name: "dashboard-province-profile"
  }, {
    path: "/dashboard/:province/projects-coming-soon",
    component: _99a521e4,
    name: "dashboard-province-projects-coming-soon"
  }, {
    path: "/dashboard/:province/qrcodes",
    component: _1a92ffbe,
    name: "dashboard-province-qrcodes"
  }, {
    path: "/dashboard/:province/short-url",
    component: _105c2acc,
    name: "dashboard-province-short-url"
  }, {
    path: "/dashboard/:province/top-ads",
    component: _7c8b9c18,
    name: "dashboard-province-top-ads"
  }, {
    path: "/dashboard/:province/tracking",
    component: _eb00a6de,
    name: "dashboard-province-tracking"
  }, {
    path: "/dashboard/:province/video-review",
    component: _0f41a0f8,
    name: "dashboard-province-video-review"
  }, {
    path: "/dashboard/:province/video-review-new",
    component: _2d1d34d2,
    name: "dashboard-province-video-review-new"
  }, {
    path: "/dashboard/:province/zones",
    component: _70b23583,
    name: "dashboard-province-zones"
  }, {
    path: "/dashboard/:province/baanprompt/agents",
    component: _a9b97bae,
    name: "dashboard-province-baanprompt-agents"
  }, {
    path: "/dashboard/:province/baanprompt/property-owner",
    component: _2f536096,
    name: "dashboard-province-baanprompt-property-owner"
  }, {
    path: "/dashboard/:province/baanprompt/refers-in",
    component: _27bbc71a,
    name: "dashboard-province-baanprompt-refers-in"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out",
    component: _3dc0b30f,
    name: "dashboard-province-baanprompt-refers-out"
  }, {
    path: "/dashboard/:province/blog/create",
    component: _77f04702,
    name: "dashboard-province-blog-create"
  }, {
    path: "/dashboard/:province/customers/agents",
    component: _8b2cd2bc,
    name: "dashboard-province-customers-agents"
  }, {
    path: "/dashboard/:province/customers/companies",
    component: _f187ccc6,
    name: "dashboard-province-customers-companies"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder",
    component: _6ab88cb3,
    name: "dashboard-province-customers-companies-home-builder"
  }, {
    path: "/dashboard/:province/customers/normals",
    component: _ee4976a4,
    name: "dashboard-province-customers-normals"
  }, {
    path: "/dashboard/:province/guideline/create",
    component: _1aa13fd2,
    name: "dashboard-province-guideline-create"
  }, {
    path: "/dashboard/:province/home-builder/leading",
    component: _3cf63284,
    name: "dashboard-province-home-builder-leading"
  }, {
    path: "/dashboard/:province/line-broadcast/broadcast-campaign",
    component: _e96ec1c8,
    name: "dashboard-province-line-broadcast-broadcast-campaign"
  }, {
    path: "/dashboard/:province/line-broadcast/dashboard-campaign",
    component: _711327ae,
    name: "dashboard-province-line-broadcast-dashboard-campaign"
  }, {
    path: "/dashboard/:province/manage-event-online/create-or-update",
    component: _1883414c,
    name: "dashboard-province-manage-event-online-create-or-update"
  }, {
    path: "/dashboard/:province/manage-home-page/communication-bar",
    component: _ac6dac88,
    name: "dashboard-province-manage-home-page-communication-bar"
  }, {
    path: "/dashboard/:province/manage-home-page/communication-pop-up",
    component: _0897e0e4,
    name: "dashboard-province-manage-home-page-communication-pop-up"
  }, {
    path: "/dashboard/:province/manage-home-page/pop-up",
    component: _44e54f0d,
    name: "dashboard-province-manage-home-page-pop-up"
  }, {
    path: "/dashboard/:province/manage-other-page/boost-company-ads",
    component: _183b51f7,
    name: "dashboard-province-manage-other-page-boost-company-ads"
  }, {
    path: "/dashboard/:province/members/create",
    component: _c20d386e,
    name: "dashboard-province-members-create"
  }, {
    path: "/dashboard/:province/posts/apartment",
    component: _dcadc99c,
    name: "dashboard-province-posts-apartment"
  }, {
    path: "/dashboard/:province/posts/external",
    component: _3e67d5be,
    name: "dashboard-province-posts-external"
  }, {
    path: "/dashboard/:province/posts/home-builder",
    component: _56d58203,
    name: "dashboard-province-posts-home-builder"
  }, {
    path: "/dashboard/:province/posts/projects",
    component: _95de09e0,
    name: "dashboard-province-posts-projects"
  }, {
    path: "/dashboard/:province/posts/secondhand",
    component: _2dbf690e,
    name: "dashboard-province-posts-secondhand"
  }, {
    path: "/dashboard/:province/profile/edit",
    component: _dc86ac6c,
    name: "dashboard-province-profile-edit"
  }, {
    path: "/dashboard/:province/projects-coming-soon/create",
    component: _4b968520,
    name: "dashboard-province-projects-coming-soon-create"
  }, {
    path: "/dashboard/:province/short-url/create",
    component: _2a1fb364,
    name: "dashboard-province-short-url-create"
  }, {
    path: "/dashboard/:province/super-admin/admins",
    component: _05edd330,
    name: "dashboard-province-super-admin-admins"
  }, {
    path: "/dashboard/:province/super-admin/feature-management",
    component: _6f615ab4,
    name: "dashboard-province-super-admin-feature-management"
  }, {
    path: "/dashboard/:province/super-admin/users",
    component: _7e81ea1c,
    name: "dashboard-province-super-admin-users"
  }, {
    path: "/dashboard/:province/top-ads/create",
    component: _c57f516c,
    name: "dashboard-province-top-ads-create"
  }, {
    path: "/dashboard/:province/video-review-new/form",
    component: _596b005c,
    name: "dashboard-province-video-review-new-form"
  }, {
    path: "/dashboard/:province/video-review/newProject",
    component: _59828fda,
    name: "dashboard-province-video-review-newProject"
  }, {
    path: "/dashboard/:province/video-review/secondHand",
    component: _32f8fd04,
    name: "dashboard-province-video-review-secondHand"
  }, {
    path: "/dashboard/:province/zones/create",
    component: _656e74ca,
    name: "dashboard-province-zones-create"
  }, {
    path: "/dashboard/:province/baanprompt/refers/create",
    component: _69735933,
    name: "dashboard-province-baanprompt-refers-create"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/create",
    component: _140d546b,
    name: "dashboard-province-customers-companies-home-builder-create"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/leading-company",
    component: _5f86d89a,
    name: "dashboard-province-customers-companies-home-builder-leading-company"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/premium",
    component: _3f7bf40a,
    name: "dashboard-province-customers-companies-home-builder-premium"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/top-ads",
    component: _69a70e9c,
    name: "dashboard-province-customers-companies-home-builder-top-ads"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/vr",
    component: _a6ef8024,
    name: "dashboard-province-customers-companies-home-builder-vr"
  }, {
    path: "/dashboard/:province/customers/companies/add-company",
    component: _3c224042,
    name: "dashboard-province-customers-companies-add-company"
  }, {
    path: "/dashboard/:province/customers/normals/create",
    component: _8b7cc860,
    name: "dashboard-province-customers-normals-create"
  }, {
    path: "/dashboard/:province/home-builder/leading/create",
    component: _76cc3bc0,
    name: "dashboard-province-home-builder-leading-create"
  }, {
    path: "/dashboard/:province/manage-home-page/pop-up/create",
    component: _7f78dd51,
    name: "dashboard-province-manage-home-page-pop-up-create"
  }, {
    path: "/dashboard/:province/manage-other-page/enrolls/interest",
    component: _35aa8ba1,
    name: "dashboard-province-manage-other-page-enrolls-interest"
  }, {
    path: "/dashboard/:province/posts/apartment/create",
    component: _69a2d468,
    name: "dashboard-province-posts-apartment-create"
  }, {
    path: "/dashboard/:province/posts/approved/projects",
    component: _c6b6905c,
    name: "dashboard-province-posts-approved-projects"
  }, {
    path: "/dashboard/:province/posts/approved/sale-and-rent",
    component: _be3bfb1a,
    name: "dashboard-province-posts-approved-sale-and-rent"
  }, {
    path: "/dashboard/:province/posts/approved/secondhand",
    component: _3ab7dfbb,
    name: "dashboard-province-posts-approved-secondhand"
  }, {
    path: "/dashboard/:province/posts/external/create",
    component: _606ad9bd,
    name: "dashboard-province-posts-external-create"
  }, {
    path: "/dashboard/:province/posts/home-builder/create",
    component: _a8e1edca,
    name: "dashboard-province-posts-home-builder-create"
  }, {
    path: "/dashboard/:province/posts/home-builder/premium",
    component: _7806a96a,
    name: "dashboard-province-posts-home-builder-premium"
  }, {
    path: "/dashboard/:province/posts/projects/create",
    component: _14c2b1ae,
    name: "dashboard-province-posts-projects-create"
  }, {
    path: "/dashboard/:province/posts/projects/exportfile",
    component: _ea8bc436,
    name: "dashboard-province-posts-projects-exportfile"
  }, {
    path: "/dashboard/:province/posts/projects/home-builder",
    component: _4e9e10a2,
    name: "dashboard-province-posts-projects-home-builder"
  }, {
    path: "/dashboard/:province/posts/projects/promo-inhouse",
    component: _4e6b2944,
    name: "dashboard-province-posts-projects-promo-inhouse"
  }, {
    path: "/dashboard/:province/posts/rejected/projects",
    component: _2bc8e2aa,
    name: "dashboard-province-posts-rejected-projects"
  }, {
    path: "/dashboard/:province/posts/rejected/sale-and-rent",
    component: _26e6e98c,
    name: "dashboard-province-posts-rejected-sale-and-rent"
  }, {
    path: "/dashboard/:province/posts/rejected/secondhand",
    component: _05d46454,
    name: "dashboard-province-posts-rejected-secondhand"
  }, {
    path: "/dashboard/:province/posts/secondhand/create",
    component: _629d6e65,
    name: "dashboard-province-posts-secondhand-create"
  }, {
    path: "/dashboard/:province/projects-coming-soon/interested/total",
    component: _e99f011c,
    name: "dashboard-province-projects-coming-soon-interested-total"
  }, {
    path: "/dashboard/:province/super-admin/admins/create",
    component: _40fe44e4,
    name: "dashboard-province-super-admin-admins-create"
  }, {
    path: "/dashboard/:province/super-admin/users/create",
    component: _7ed69e0c,
    name: "dashboard-province-super-admin-users-create"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/top-ads/create",
    component: _1a21e0bc,
    name: "dashboard-province-customers-companies-home-builder-top-ads-create"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/vr/create",
    component: _0c340890,
    name: "dashboard-province-customers-companies-home-builder-vr-create"
  }, {
    path: "/dashboard/:province/manage-other-page/enrolls/interest/create",
    component: _b1499986,
    name: "dashboard-province-manage-other-page-enrolls-interest-create"
  }, {
    path: "/dashboard/:province/posts/projects/promo-inhouse/create",
    component: _68374b20,
    name: "dashboard-province-posts-projects-promo-inhouse-create"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/premium/post-populars/:premiumId",
    component: _527693fc,
    name: "dashboard-province-customers-companies-home-builder-premium-post-populars-premiumId"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/top-ads/edit/:id",
    component: _5201f24a,
    name: "dashboard-province-customers-companies-home-builder-top-ads-edit-id"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/vr/edit/:id",
    component: _9a2d076e,
    name: "dashboard-province-customers-companies-home-builder-vr-edit-id"
  }, {
    path: "/dashboard/:province/manage-other-page/enrolls/interest/details/:id?",
    component: _184e2f08,
    name: "dashboard-province-manage-other-page-enrolls-interest-details-id"
  }, {
    path: "/dashboard/:province/manage-other-page/enrolls/interest/edit/:id?",
    component: _20f0afb6,
    name: "dashboard-province-manage-other-page-enrolls-interest-edit-id"
  }, {
    path: "/dashboard/:province/posts/projects/promo-inhouse/edit/:id",
    component: _1b265ad9,
    name: "dashboard-province-posts-projects-promo-inhouse-edit-id"
  }, {
    path: "/dashboard/:province/baanprompt/agents/edit/:id?",
    component: _6b720c2e,
    name: "dashboard-province-baanprompt-agents-edit-id"
  }, {
    path: "/dashboard/:province/baanprompt/agents/register/:id?",
    component: _0f736972,
    name: "dashboard-province-baanprompt-agents-register-id"
  }, {
    path: "/dashboard/:province/customers/agents/edit/:id?",
    component: _165b8dd6,
    name: "dashboard-province-customers-agents-edit-id"
  }, {
    path: "/dashboard/:province/customers/agents/register/:id?",
    component: _4a67acae,
    name: "dashboard-province-customers-agents-register-id"
  }, {
    path: "/dashboard/:province/customers/companies/edit/:id",
    component: _e7d6f986,
    name: "dashboard-province-customers-companies-edit-id"
  }, {
    path: "/dashboard/:province/customers/normals/edit/:id?",
    component: _13df3f89,
    name: "dashboard-province-customers-normals-edit-id"
  }, {
    path: "/dashboard/:province/home-builder/leading/edit/:id",
    component: _4f7cb90e,
    name: "dashboard-province-home-builder-leading-edit-id"
  }, {
    path: "/dashboard/:province/posts/apartment/edit/:id?",
    component: _c514aaf6,
    name: "dashboard-province-posts-apartment-edit-id"
  }, {
    path: "/dashboard/:province/posts/external/edit/:id?",
    component: _569e9394,
    name: "dashboard-province-posts-external-edit-id"
  }, {
    path: "/dashboard/:province/posts/home-builder/edit/:id",
    component: _30f2f3d8,
    name: "dashboard-province-posts-home-builder-edit-id"
  }, {
    path: "/dashboard/:province/posts/projects/edit/:id?",
    component: _5b1b5432,
    name: "dashboard-province-posts-projects-edit-id"
  }, {
    path: "/dashboard/:province/posts/secondhand/edit/:id?",
    component: _1490c0de,
    name: "dashboard-province-posts-secondhand-edit-id"
  }, {
    path: "/dashboard/:province/posts/secondhand/user/:id",
    component: _5ceefe3c,
    name: "dashboard-province-posts-secondhand-user-id"
  }, {
    path: "/dashboard/:province/super-admin/users/edit/:id",
    component: _073ca2c5,
    name: "dashboard-province-super-admin-users-edit-id"
  }, {
    path: "/dashboard/:province/posts/projects/manage-vr/:id?/edit",
    component: _332ad2ff,
    name: "dashboard-province-posts-projects-manage-vr-id-edit"
  }, {
    path: "/dashboard/:province/posts/projects/promo-inhouse/:id?/coupons",
    component: _86e14b78,
    name: "dashboard-province-posts-projects-promo-inhouse-id-coupons"
  }, {
    path: "/dashboard/:province/posts/projects/promo-inhouse/:id?/participants",
    component: _a234270e,
    name: "dashboard-province-posts-projects-promo-inhouse-id-participants"
  }, {
    path: "/dashboard/:province/baanprompt/refers/:id",
    component: _6eacf1b9,
    name: "dashboard-province-baanprompt-refers-id"
  }, {
    path: "/dashboard/:province/boost-posts/edit/:id",
    component: _088dabb2,
    name: "dashboard-province-boost-posts-edit-id"
  }, {
    path: "/dashboard/:province/boost-vr/edit/:id",
    component: _ce2db292,
    name: "dashboard-province-boost-vr-edit-id"
  }, {
    path: "/dashboard/:province/customers/companies/:id",
    component: _69051e08,
    name: "dashboard-province-customers-companies-id"
  }, {
    path: "/dashboard/:province/manage-home-page/pop-up/:id",
    component: _1b361b78,
    name: "dashboard-province-manage-home-page-pop-up-id"
  }, {
    path: "/dashboard/:province/members/permissions/:id?",
    component: _02c66232,
    name: "dashboard-province-members-permissions-id"
  }, {
    path: "/dashboard/:province/projects-coming-soon/edit/:id?",
    component: _03907929,
    name: "dashboard-province-projects-coming-soon-edit-id"
  }, {
    path: "/dashboard/:province/projects-coming-soon/interested/:index",
    component: _7eaa5c15,
    name: "dashboard-province-projects-coming-soon-interested"
  }, {
    path: "/dashboard/:province/top-ads/edit/:id",
    component: _321d0483,
    name: "dashboard-province-top-ads-edit-id"
  }, {
    path: "/dashboard/:province/zones/update/:id",
    component: _0cf1d4d6,
    name: "dashboard-province-zones-update-id"
  }, {
    path: "/dashboard/:province/baanprompt/agents/:id/verify",
    component: _9547c4b2,
    name: "dashboard-province-baanprompt-agents-id-verify"
  }, {
    path: "/dashboard/:province/baanprompt/refers-in/:id?/accept-agent",
    component: _12e4219d,
    name: "dashboard-province-baanprompt-refers-in-id-accept-agent"
  }, {
    path: "/dashboard/:province/baanprompt/refers-in/:id?/sent-agents",
    component: _4424ed54,
    name: "dashboard-province-baanprompt-refers-in-id-sent-agents"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/assign-document",
    component: _43f19024,
    name: "dashboard-province-baanprompt-refers-out-id-assign-document"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/interest",
    component: _1661b096,
    name: "dashboard-province-baanprompt-refers-out-id-interest"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/monthly",
    component: _281df210,
    name: "dashboard-province-baanprompt-refers-out-id-monthly"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/offline-marketing",
    component: _7ec64ee7,
    name: "dashboard-province-baanprompt-refers-out-id-offline-marketing"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/online-marketing",
    component: _2495cd37,
    name: "dashboard-province-baanprompt-refers-out-id-online-marketing"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/visit",
    component: _5dd2da94,
    name: "dashboard-province-baanprompt-refers-out-id-visit"
  }, {
    path: "/dashboard/:province/baanprompt/refers/:id?/edit",
    component: _2abd9673,
    name: "dashboard-province-baanprompt-refers-id-edit"
  }, {
    path: "/dashboard/:province/customers/agents/:id/verify",
    component: _6764acce,
    name: "dashboard-province-customers-agents-id-verify"
  }, {
    path: "/dashboard/:province/customers/companies-home-builder/:id/edit",
    component: _1aebd16e,
    name: "dashboard-province-customers-companies-home-builder-id-edit"
  }, {
    path: "/dashboard/:province/customers/companies/:id/company_list",
    component: _3602ec5a,
    name: "dashboard-province-customers-companies-id-company_list"
  }, {
    path: "/dashboard/:province/manage-event-online/promotion/:id?/create",
    component: _58ff2556,
    name: "dashboard-province-manage-event-online-promotion-id-create"
  }, {
    path: "/dashboard/:province/super-admin/admins/:id?/edit",
    component: _50bcc45e,
    name: "dashboard-province-super-admin-admins-id-edit"
  }, {
    path: "/dashboard/:province/video-review/newProject/:slot/create",
    component: _434c0ace,
    name: "dashboard-province-video-review-newProject-slot-create"
  }, {
    path: "/dashboard/:province/video-review/secondHand/:slot/create",
    component: _11dfc1af,
    name: "dashboard-province-video-review-secondHand-slot-create"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/interest/details/:details_id",
    component: _230ff420,
    name: "dashboard-province-baanprompt-refers-out-id-interest-details-details_id"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/monthly/details/:details_id",
    component: _d606b49a,
    name: "dashboard-province-baanprompt-refers-out-id-monthly-details-details_id"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/offline-marketing/details/:detail_id?",
    component: _faa2241c,
    name: "dashboard-province-baanprompt-refers-out-id-offline-marketing-details-detail_id"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/online-marketing/details/:detail_id?",
    component: _78699cbc,
    name: "dashboard-province-baanprompt-refers-out-id-online-marketing-details-detail_id"
  }, {
    path: "/dashboard/:province/baanprompt/refers-out/:id?/visit/details/:details_id",
    component: _4716331e,
    name: "dashboard-province-baanprompt-refers-out-id-visit-details-details_id"
  }, {
    path: "/dashboard/:province/video-review/newProject/:slot/edit/:id?",
    component: _27fc46cf,
    name: "dashboard-province-video-review-newProject-slot-edit-id"
  }, {
    path: "/dashboard/:province/video-review/secondHand/:slot/edit/:id?",
    component: _2705ee36,
    name: "dashboard-province-video-review-secondHand-slot-edit-id"
  }, {
    path: "/dashboard/:province/manage-event-online/promotion/:id?/:promotionId",
    component: _3975d510,
    name: "dashboard-province-manage-event-online-promotion-id-promotionId"
  }, {
    path: "/dashboard/:province/announcement/:company",
    component: _6979ba3f,
    name: "dashboard-province-announcement-company"
  }, {
    path: "/dashboard/:province/banners/:type",
    component: _5a93d08d,
    name: "dashboard-province-banners-type"
  }, {
    path: "/dashboard/:province/company/:id",
    component: _ad41d338,
    name: "dashboard-province-company-id"
  }, {
    path: "/dashboard/:province/short-url/:id?",
    component: _1c2dcb82,
    name: "dashboard-province-short-url-id"
  }, {
    path: "/dashboard/:province/banners/:type?/create",
    component: _1f9a8bd1,
    name: "dashboard-province-banners-type-create"
  }, {
    path: "/dashboard/:province/blog/:id/edit",
    component: _11dc7a45,
    name: "dashboard-province-blog-id-edit"
  }, {
    path: "/dashboard/:province/guideline/:id/edit",
    component: _679b01d6,
    name: "dashboard-province-guideline-id-edit"
  }, {
    path: "/dashboard/:province/manage-event-online/:eventId?/detail",
    component: _31cf3ca2,
    name: "dashboard-province-manage-event-online-eventId-detail"
  }, {
    path: "/dashboard/:province/manage-event-online/:eventId?/interest",
    component: _58a80cbb,
    name: "dashboard-province-manage-event-online-eventId-interest"
  }, {
    path: "/dashboard/:province/members/:id/edit",
    component: _c9dcfd68,
    name: "dashboard-province-members-id-edit"
  }, {
    path: "/dashboard/:province/banners/:type?/:id/edit",
    component: _7808c754,
    name: "dashboard-province-banners-type-id-edit"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
